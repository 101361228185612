var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-data-table',{attrs:{"hide-add":"","title":"Reports","headers":_vm.headers,"provider":_vm.$reports,"actions":_vm.actions,"fetch-function":_vm.onFetchReports},scopedSlots:_vm._u([{key:"item.iReportId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-text-primary tw-text-base tw-font-ave tw-cursor-pointer"},[_vm._v(" #"+_vm._s(item.iReportId)+" ")])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-items-center tw-space-x-2"},[_c('v-avatar',{attrs:{"size":"48"}},[_c('v-img',{ref:"profileImageRef",staticClass:"tw-border tw-border-accent",attrs:{"src":_vm.$helper.imgSrc(item.customer.user.vProfileImage, {
								type: 'users',
							})},on:{"error":function($event){return _vm.$helper.vImgErr({
								size: '48x48',
								ref: _vm.$refs.profileImageRef,
							})}}})],1),_c('div',{staticClass:"tw-font-ave tw-font-medium tw-text-primary tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.$helper.fullName(item.customer.user))+" ")])],1)]}},{key:"item.eStatus",fn:function(ref){
							var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"dark":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(item.eStatus || 'Fallback'))])]}}],null,true)})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }