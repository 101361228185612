












































































import Vue from 'vue'

import { Pagination } from 'vuelpers'
import { Report, Query } from '@/types'
import { Accessors } from 'vue/types/options'
import { mapActions, mapGetters } from 'vuex'

// import ReportView from '@/components/ReportView.vue'
import BaseDataTable from '@/components/base/BaseDataTable.vue'
// import { StatusColor } from '@/consts'

export default Vue.extend({
	name: 'Reports',
	metaInfo: {
		title: 'Reports | Admin | zx55',
	},
	components: {
		// ReportView,
		BaseDataTable,
	},
	data() {
		return {
			// statusColor: StatusColor,
			// orderStatus: ReportStatus,
			headers: [
				{ text: 'Id', value: 'iReportId' },
				{ text: 'Customer', value: 'customer' },
				{ text: 'ReportableType', value: 'reportableType' },
				{ text: 'Description', value: 'tDescription' },
				{ text: 'Attachments', value: 'vImage' },
				{ text: 'Status', value: 'eStatus' },
				// { text: 'Actions', value: 'actions' },
			],
			actions: [
				{
					icon: 'mdi-eye',
					text: 'View Details',
					event: 'view',
				},
			],
		}
	},
	computed: {
		...({
			...mapGetters('reports', ['$reports']),
		} as Accessors<{
			$reports: Pagination<Report>
		}>),
	},
	methods: {
		...mapActions('reports', ['fetchReports']),
		onFetchReports(query: any) {
			return this.fetchReports({
				...query,
			} as Query)
		},
	},
})
